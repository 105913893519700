import React from 'react'
import axios from 'axios'
axios.defaults.baseURL = "https://vostorg-api.skb-44.ru/api";

export const isAuth = () => {
    const token = localStorage.getItem('vostorg-token')
    if(token){
        return true
    } 
    else{
        return false
    }
}


const Logic = () => {
    return 
}

export default Logic